<template>
  <div />
</template>

<style lang="scss">
  body {
    color: #000 !important;
  }

  .theme--broker {
    background-color: #24385b !important;
    color: #FFF !important;

    .footer-contact {
      justify-content: center !important;
      background-color: #24385b !important;
      padding-top: 0;

      @include mq(medium) {
        justify-content: center !important;
      }

      p {
        @include type-body-s;

        text-align: center !important;
      }
    }

    .footer-contact.footer-contact--minimal {
      padding-top: rem(24px) !important;

      @include mq(medium) {
        padding-top: rem(64px) !important;
      }

      .contact {
        p {
          color: #FFF !important;
        }

        a {
          color: #FFF !important;
        }
      }
    }

    .footer-links {
      a {
        color: #FFF !important;

        &:hover {
          color: #FFF !important;
        }
      }
    }

    .footer-sticky-cta-spacing {
      background-color: #24385b !important;
    }
  }
</style>
